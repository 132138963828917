<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    :title="'Изменение услуги'"
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
    />
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group label="Название услуги">
            <b-form-input
              v-model="$v.serviceData.name.$model"
              :disabled="!isChange"
              type="text"
              :state="validateState('name')"
              :placeholder="'Введите название услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Описание услуги">
            <classic-editor
              v-model="$v.serviceData.description.$model"
              :disabled="!isChange"
              :class="{
                'is-invalid': validateState('description') === false,
              }"
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            :label="`Подготовка к анализу / ${countSelectedPreparation}`"
          >
            <span v-if="errors.preparations" class="error-text">
              {{ errors.preparations }}
            </span>
            <base-dropdown
              v-if="preparation && preparation?.length"
              header="Выбрать"
              class="crm-form-step"
              :open="steps['1'].open"
              @toggleShowStatus="toggleStepShowStatus(1)"
            >
              <template v-if="steps['1'].open">
                <div class="grid gap-y-2 p-3 bg-v-ui-background-monochrome-5">
                  <b-form-checkbox
                    v-for="(item, index) in preparation"
                    :key="item.id"
                    v-model="preparationList[index].status"
                    name="checkbox-1"
                    switch
                  >
                    <preparation-analyses-card
                      :icon="item.logoUrl"
                      :text="item.text"
                      class="cursor-pointer"
                    />
                  </b-form-checkbox>
                </div>
              </template>
            </base-dropdown>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-form-group label="Код услуги">
            <b-form-input
              v-model="$v.serviceData.code.$model"
              type="text"
              :disabled="!isChange"
              :state="validateState('code')"
              :placeholder="'Введите код услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row> -->
      <!-- <b-row>
        <b-col>
          <b-form-group label="Стоимость услуги">
            <b-form-input
              v-model="serviceData.cost"
              :placeholder="'Введите стоимость услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select
              v-model="serviceData.isActive"
              :disabled="!isChange"
              :options="optionsActivate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-form-checkbox
            v-model="isCheckup"
            size="sm"
            :disabled="!isChange"
            @change="onCheckUp"
          >
            <span class="text-secondary font-weight-bold"> Чекап </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <transition name="fade">
        <b-row v-if="isCheckup">
          <b-col>
            <b-form-group label="Дочерние услуги">
              <multiselect
                v-model="serviceData.childs"
                :multiple="true"
                placeholder="Выберите дочернюю услугу"
                :disabled="!isChange"
                :close-on-select="false"
                label="name"
                track-by="id"
                :searchable="true"
                :options="serviceList"
                @search-change="fetchServices"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </transition>
      <b-row v-if="$v.serviceData.childs.$error" class="mb-5">
        <b-col>
          <div class="validation-errors">
            <span class="validation-error-text">
              Данное поле обязательно*
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-form-checkbox
            v-model="$v.serviceData.isRecommended.$model"
            size="sm"
            :disabled="!isChange"
          >
            <span class="text-secondary font-weight-bold"> Советуем сдать </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <transition name="fade">
        <b-row v-if="serviceData.isRecommended">
          <b-col>
            <b-form-group label="Приоритет услуги (приоритет выше у большего значения)">
              <b-form-input
                v-model.trim.lazy="$v.serviceData.recommendationPriority.$model"
                :disabled="!isChange"
                type="number"
                :state="validateState('recommendationPriority')"
                min="1"
                :placeholder="'Введите приоритет услуги'"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">
                {{ !$v.serviceData.recommendationPriority.required ? 'Данное поле обязательно*' : 'Значение должно быть больше 1' }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </transition>
      <b-row>
        <b-col>
          <b-form-group label="Категории">
            <multiselect
              v-model="serviceData.tags"
              :multiple="true"
              placeholder="Выберите категорию"
              :close-on-select="false"
              :disabled="!isChange"
              label="name"
              track-by="id"
              :options="tags"
              @search-change="fetchTags"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Синонимы">
            <div class="flex gap-x-4 items-end">
              <b-form-input
                v-model="synonym"
                :disabled="!isChange"
                type="text"
                :placeholder="'Введите синонимы к услуге'"
                trim
                @keydown.enter="onAddSynonym()"
              />
              <b-button
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                class="shrink-0"
                @click="onAddSynonym()"
              >
                Добавить
              </b-button>
            </div>
            <ul
              v-if="serviceData.synonyms && serviceData.synonyms?.length"
              class="mt-3 flex gap-2 flex-wrap"
            >
              <li
                v-for="(synonym, index) in serviceData.synonyms"
                :key="synonym"
                class="
                  flex
                  justify-start
                  items-center
                  min-h-6
                  p-2
                  note-12-reg-400
                  gap-2
                  rounded
                  text-v-ui-text-service-info-monochrome
                  bg-v-ui-light-service-info-monochrome
                "
              >
                <span>{{ synonym }}</span>
                <button
                  style="color: red"
                  class="text-sm"
                  @click="onRemoveSynonym(index)"
                >
                  X
                </button>
              </li>
            </ul>
            <div
              v-if="serviceData.synonyms && serviceData.synonyms?.length"
              class="p-4 bg-v-ui-background-monochrome-5"
            >
              <synonyms :synonyms="serviceData.synonyms" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          :disabled="!isChange"
          @click="onClickUpdateService"
        >
          Изменить
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          :disabled="!isChange"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import { api } from '@/helpers/api';
import debounce from 'lodash.debounce';
import { mixinRoles } from '@/mixins';
import PreparationAnalysesCard from '@/components/Laboratories/PreparationAnalysesCard';
import { BaseDropdown } from '@/components/base';
import Synonyms from '@/components/Laboratories/Modals/Services/Synonyms.vue';
import ClassicEditor from '@/components/common/CKEditor/ClassicEditorComponent.vue';

const FETCH_DELAY = 700;

export default {
  name: 'EditLaboratoryServiceModal',
  components: {
    ClassicEditor,
    PreparationAnalysesCard,
    BaseDropdown,
    Synonyms,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    serviceId: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
    preparation: {
      type: Array,
      default: () => [],
    },
    cities: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isCheckup: false,
      rows: [],
      serviceData: {
        childs: [],
        name: '',
        description: '',
        // code: '',
        cost: null,
        id: null,
        isActive: null,
        tags: [],
        synonyms: null,
        isRecommended: false,
        recommendationPriority: null,
      },
      optionsActivate: [
        { value: null, text: 'Выберите состояние' },
        { value: true, text: 'Активирован' },
        { value: false, text: 'Деактивирован' },
      ],
      serviceList: [],
      tagsId: [],
      tags: [],
      synonym: '',
      preparationList: [],
      steps: {
        1: {
          open: false,
          disabled: false,
        },
      },
      errors: {
        preparations: null,
      },
    };
  },
  validations() {
    return {
      serviceData: {
        name: { required },
        // code: { required },
        description: { required },
        childs: {
          required: this.isCheckup ? required : () => true,
        },
        recommendationPriority: {
          required: requiredIf(() => this.serviceData.isRecommended),
          minValue: minValue(1),
        },
        isRecommended: {},
      },
    };
  },
  computed: {
    isChange() {
      return this.checkFeatureAccess({
        name: 'Изменение услуги',
        url: '/laboratory-service',
      });
    },
    countSelectedPreparation() {
      if (this.preparationList?.length) {
        const listSelectedItems = this.preparationList.filter(
          (item) => item.status,
        );
        return listSelectedItems.length;
      }
      return 0;
    },
    selectedPreparation() {
      if (this.preparationList?.length) {
        const listSelectedItems = this.preparationList.filter(
          (item) => item.status,
        );
        return listSelectedItems.map((item) => item.id);
      }
      return [];
    },
  },
  watch: {
    'serviceData.isRecommended': function (newVal) {
      if (!newVal) {
        this.serviceData.recommendationPriority = null;
      }
    },
  },
  async created() {
    await this.fetchServiceInfo();
    await this.fetchServices();
    await this.fetchTags();
    this.setPreparationList();
    if (this.serviceData.childs?.length) this.isCheckup = true;
  },
  methods: {
    setPreparationList() {
      if (this.preparation?.length) {
        let ids = [];

        if (this.serviceData.preparations?.length) {
          ids = this.serviceData.preparations.map((el) => el.id);
        }

        const newPreparation = this.preparation.map((item) => {
          let isSelected = false;

          if (ids?.length) {
            isSelected = ids.findIndex((id) => id === item.id) !== -1;
          }

          return {
            id: item.id,
            status: isSelected,
          };
        });
        this.preparationList = [...newPreparation];
      }
    },
    onCheckUp() {
      this.serviceData.childs = [];
    },
    async onClose() {
      this.$emit('input', false);
    },
    async fetchServiceInfo() {
      this.isLoading = true;
      const { data } = await this.$store.dispatch(
        this.$types.GET_LABORATORY_SERVICE_FOR_ID,
        this.serviceId,
      );
      this.serviceData = data;
      this.isLoading = false;
    },
    fetchTags: debounce(async function fetchTags(query) {
      const {
        data: { data },
      } = await this.$store.dispatch(this.$types.GET_LABORATORY_TAGS, {
        isActive: true,
        Name: query,
      });

      this.tags = data;
    }, FETCH_DELAY),
    fetchServices: debounce(async function fetchServices(query) {
      const params = new URLSearchParams();

      params.append('isMerged', true);
      if (query) params.append('Name', query);

      const {
        data: { data },
      } = await api.get('v3/laboratory/service/list', {
        params,
      });
      this.serviceList = data;
    }, FETCH_DELAY),
    async onClickUpdateService() {
      if (!this.onCheckValidation()) return;

      try {
        const tagsIds = this.serviceData.tags?.map((item) => item.id);
        const childIds = this.serviceData.childs?.map((item) => item.id);
        const params = {
          name: this.serviceData.name,
          // code: this.serviceData.code,
          cost: +this.serviceData.cost,
          id: this.serviceData.id,
          description: this.serviceData.description,
          preparations: this.selectedPreparation,
          childs: childIds,
          tags: tagsIds,
          isActive: this.serviceData.isActive ?? false,
          synonyms: this.serviceData.synonyms,
          isRecommended: this.serviceData.isRecommended,
          recommendationPriority: this.serviceData.recommendationPriority,
        };

        await this.$store.dispatch(
          this.$types.LABORATORY_SERVICE_UPDATE,
          params,
        );
        await this.$store.dispatch(
          this.$types.GET_LABORATORY_SERVICES,
          this.params,
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.onClose();
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.serviceData[name];
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      this.$v.serviceData.name.$touch();
      // this.$v.serviceData.code.$touch();
      this.$v.serviceData.description.$touch();
      this.$v.serviceData.childs.$touch();
      this.$v.serviceData.recommendationPriority.$touch();
      console.log(this.$v);
      this.errors.preparations = null;
      if (!this.selectedPreparation?.length) {
        this.errors.preparations = 'Данное поле обязательно*';
        return false;
      }
      return !this.$v.$error;
    },
    onAddSynonym() {
      if (!this.synonym.length) return;

      if (!this.serviceData.synonyms) {
        this.serviceData.synonyms = [];
      }

      this.serviceData.synonyms.push(this.synonym);
      this.synonym = '';
    },
    onRemoveSynonym(index) {
      this.serviceData.synonyms.splice(index, 1);
      if (!this.serviceData.synonyms.length) {
        this.serviceData.synonyms = null;
      }
    },
    toggleStepShowStatus(index) {
      this.steps[index].open = !this.steps[index].open;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.custom-switch .custom-control-label {
  width: 100%;
}

::v-deep.base-dropdown-wrapper {
  @apply mb-0;
}

.crm-table {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #f3f3f3;
      color: #6e88f3;
    }

    & + & {
      border-top: 1px solid #e0e0e0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #e0e0e0;
    }
  }
}
.error-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.is-invalid {
  --ck-color-base-border: #dc3545;
  --ck-focus-ring: 1px solid #dc3545;
}
</style>
